import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import de from 'Translations/de.json';
import en from 'Translations/en.json';
import es from 'Translations/es.json';
import fr from 'Translations/fr.json';
import it from 'Translations/it.json';
import pt from 'Translations/pt.json';
import ru from 'Translations/ru.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'it', 'fr', 'de', 'es', 'pt', 'ru'],
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ['querystring', 'navigator', 'cookie', 'localStorage'],
      caches: ['cookie', 'localStorage'],
    },
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
      de: {
        translation: de,
      },
      ru: {
        translation: ru,
      },
      es: {
        translation: es,
      },
      it: {
        translation: it,
      },
      pt: {
        translation: pt,
      },
    },
  });

export default i18n;
