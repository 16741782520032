import './App.scss';

import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router';

import { WpsContext, store } from './Store';

import 'i18n';
import { router } from 'routes';

const App = () => {
  return (
    <Provider store={store} context={WpsContext}>
      <RouterProvider router={router} />
    </Provider>
  );
};

export default App;
