import type { CardTracking, PromoTracking } from 'Utils/utilities';

const maxEvents = 20;

class ImpressionHandler {
  readonly #impressions = new Set<CardTracking | PromoTracking>();
  readonly #method: (impressions: (CardTracking | PromoTracking)[]) => void;

  private constructor(method: (impressions: (CardTracking | PromoTracking)[]) => void) {
    this.#method = method;
    document.addEventListener('visibilitychange', () => {
      document.visibilityState === 'hidden' && this.send();
    });
  }

  add = (impression: CardTracking | PromoTracking) => {
    this.#impressions.add(impression);
    if (this.#impressions.size >= maxEvents) {
      this.send();
    }
  };

  send = () => {
    if (!this.#impressions.size) return;

    this.#method(Array.from(this.#impressions));
    this.#impressions.clear();
  };

  static readonly sendAll = () => {
    ImpressionHandler.#handlers.forEach((handler) => handler.send());
  };

  static readonly #handlers = new Map<string, ImpressionHandler>();
  static readonly getInstance = (key: string, method?: (impressions: (CardTracking | PromoTracking)[]) => void) => {
    if (!ImpressionHandler.#handlers.has(key) && method) {
      ImpressionHandler.#handlers.set(key, new ImpressionHandler(method));
    }

    return ImpressionHandler.#handlers.get(key);
  };
}

export default ImpressionHandler;
