import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

declare global {
  interface Window {
    queryClient: QueryClient;
  }
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
  },
});
window.queryClient = queryClient;

export const ReactQueryProvider = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
